<template>
    <div class="page">
        <tableview ref="table" :url="API.Market.shop.list" v-model="searchData" :checkFun="checkFun">
            <template #search>
                <el-input v-model="searchData.names" size="small" clearable placeholder="按名称查询"></el-input>
                <el-select v-model="searchData.platform" placeholder="请选择生效平台" clearable size="small">
                    <el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
                </el-select>
                <el-select v-model="searchData.status" placeholder="请选择上架状态" clearable size="small">
                    <el-option label="上架" :value="1"></el-option>
                    <el-option label="下架" :value="0"></el-option>
                </el-select>
            </template>
            <template #bar>
                <el-button @click="addOne" size="small" icon="el-icon-plus" plain>新增商品</el-button>
            </template>
            <template #column>
                <el-table-column prop="names" label="商品名称" />
                <el-table-column prop="price" label="价格(元)" />
                <el-table-column prop="platform" label="生效平台">
                    <template v-slot="{row}">
                        {{ $platform[row.platform] }}
                    </template>
                </el-table-column>
                <el-table-column prop="btime" label="有效时间" width="200">
                    <template v-slot="{row}">
                        {{ row.btime + " ~ " + row.etime }}
                    </template>
                </el-table-column>
                <el-table-column prop="status_text" label="上架状态" />
                <el-table-column prop="sortval" label="排序" />
                <el-table-column prop="utime" label="编辑时间" />
                <el-table-column label="操作" width="300">
                    <template v-slot="{ row }">
                        <el-button size="small" plain @click="edit(row)">编辑</el-button>
                        <!--<el-button size="small" plain @click="del(row)">删除</el-button>-->
                    </template>
                </el-table-column>
            </template>
        </tableview>

        <!-- 编辑 -->
        <el-dialog title="新增/编辑" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="900px">
            <el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="120px" class="form">
                <el-form-item label="商品名称" prop="names">
                    <el-input v-model="editDialogFormData.names" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="商品价格" prop="price">
                    <el-input-number v-model="editDialogFormData.price" controls-position="right" size="mini" :precision="2" :step="1" :min="0.01" :max="999999"></el-input-number>
                </el-form-item>
                <el-form-item label="商品原价" prop="origin_price">
                    <el-input-number v-model="editDialogFormData.origin_price" controls-position="right" size="mini" :precision="2" :step="1" :min="0" :max="999999"></el-input-number>
                </el-form-item>
                <el-form-item label="快递费" prop="send_price">
                    <el-input-number v-model="editDialogFormData.send_price" controls-position="right" size="mini" :precision="2" :step="1" :min="0" :max="100"></el-input-number>
                </el-form-item>
                <el-form-item label="商品标签" prop="tag">
                    <el-select size="mini" v-model="editDialogFormData.tag">
                        <el-option v-for="item in tags" :label="item.v" :value="item.k" :key="item.k"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="归属城市" prop="city">
					<el-select v-model="editDialogFormData.city" placeholder="请选择" style="width:100%">
						<el-option label="全部" value="全部"></el-option>
						<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item> -->
                <el-form-item label="生效平台" prop="platform">
					<el-select v-model="editDialogFormData.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="封面图" prop="img_face">
                    <upload style="display: flex;margin: 0 10px;" type="image" :imgWidth="80" :imgHeight="80" :fileList="editDialogFormData.img_face_file" @changeFile="faceFile($event)"></upload>
                </el-form-item>
                <el-form-item label="详情图" prop="img_detail">
                    <upload style="display: flex;margin: 0 10px;" type="image" :imgWidth="80" :imgHeight="80" :fileList="editDialogFormData.img_detail_file" @changeFile="detailFile($event)"></upload>
                </el-form-item>
                <el-form-item label="商品描述" prop="description">
                    <quill-editor v-model="editDialogFormData.description"></quill-editor>
                </el-form-item>
                <el-form-item label="排序值(小排前)" prop="sortval">
                    <el-input v-model="editDialogFormData.sortval" size="mini"></el-input>
                </el-form-item>
                <el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime"
						type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime"
						type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="上架状态" prop="status">
					<el-switch v-model="editDialogFormData.status" :active-value="1" :inactive-value="0" />
				</el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
                </el-button>
                <el-button size="small" plain icon="el-icon-close" @click="editDialogShow = false">取 消</el-button>
            </div>
        </el-dialog>        
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Vue from 'vue'
import store from '@/store'
import API from '@/service/api.js'
import { MessageBox, Notification , Message} from 'element-ui'
import tableview from '@/components/tableview.vue'
import upload from '@/components/upload'
import { get_types, tags, status_vals } from '../energy/const.js'
import { quillEditor } from 'vue-quill-editor'
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

const searchData = ref({});
const editForm = ref(null);
const rules = ref({
	names: [{
		required: true,
		message: '请输入商品名称',
		trigger: 'change'
	}],
	price: [{
        type: 'number',
        min:0.01,
		required: true,
		message: '请输入商品价格',
		trigger: 'change'
	}],
    send_price: [{
        type: 'number',
		required: true,
		message: '请输入快递费',
		trigger: 'change'
	}],
	img_face: [{
        type: 'integer',
        min:1,
		required: true,
		message: '请添加封面图'
	}],
	img_detail: [{
        type: 'integer',
        min:1,
		required: true,
		message: '请添加详情图'
	}],
	description: [{
		required: true,
		message: '请输入商品描述',
		trigger: 'change'
	}],
	sortval: [{
		required: true,
		message: '请输入排序值',
		trigger: 'change'
	}],
	btime: [{
		required: true,
		message: '请输入开始时间',
		trigger: 'change'
	}],
	etime: [{
		required: true,
		message: '请输入结束时间',
		trigger: 'change',
        validator: (rule, value, callback)=>{
            if(editDialogFormData.value.etime < editDialogFormData.value.btime) return callback(new Error('时间错误'));
            callback();
        }
	}],
});
const editDialogShow = ref(false);
const editDialogFormData = ref({get_type:1,get_ids:[]});
const table = ref(null);

onMounted(()=>{
});

// 处理数据
function checkFun(data){
    for(let d of data){
        d.status_text = status_vals[d.status];
        if(d.img_face_url){
            d.img_face_file = [{attach_id:d.img_face,url:d.img_face_url}];
        }
        if(d.img_detail_url){
            d.img_detail_file = [{attach_id:d.img_detail,url:d.img_detail_url}];
        }
    }
}

// 重置form
function resetFormData() {
	editDialogFormData.value = { sortval:0, tag:0, city:"全部", platform:0, img_face:0, img_detail:0}
	if (editForm.value) {
		editForm.value.resetFields()
	}
}
// 新增
function addOne() {
	resetFormData()
	editDialogShow.value = true
}
// 编辑
async function edit(row) {
	resetFormData();
	editDialogFormData.value = Object.assign({}, row);
	delete editDialogFormData.value._XID;
	editDialogShow.value = true
}
// 删除
function del(row) {
	MessageBox.confirm('确定要删除该记录吗', '提示', {
		cancelButtonClass: 'btn-custom-cancel',
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	}).then(async () => {
		const params = {
			token: store.state.user.token,
			id: row.id
		}
		await API.Market.shop.del(params)
		Notification({title: '成功',message: '删除成功',type: 'success'})
		table.value.getlist();
	})
}
// 编辑保存
function saveEdit() {
    console.log(editDialogFormData.value);
	editForm.value.validate(async valid => {
		if (valid) {
            editDialogFormData.value.description = editDialogFormData.value.description.replace(/\<img src/g, "<img style='max-width:100%' src");
			let params = Object.assign({}, editDialogFormData.value);
            //console.log(editDialogFormData.value.description);
			if (editDialogFormData.value.id > 0) {
				await API.Market.shop.edit(params);
			} else {
				await API.Market.shop.add(params);
			}
			Notification({title: '成功',message: '操作成功',type: 'success'});
			editDialogShow.value = false;
			table.value.getlist();
		}
	})
}
function faceFile(fileInfo) { //上传的文件
    if(fileInfo.length > 0){
        editDialogFormData.value.img_face_file = fileInfo;
        editDialogFormData.value.img_face = fileInfo[0].attach_id|0;
        editDialogFormData.value.img_face_url = fileInfo[0].url;
    }else{
        editDialogFormData.value.img_face_file = [];
        editDialogFormData.value.img_face = 0;
        editDialogFormData.value.img_face_url = "";
    }
}
function detailFile(fileInfo) { //上传的文件
    if(fileInfo.length > 0){
        editDialogFormData.value.img_detail = fileInfo[0].attach_id|0;
        editDialogFormData.value.img_detail_file = fileInfo;
        editDialogFormData.value.img_detail_url = fileInfo[0].url;
    }else{
        editDialogFormData.value.img_detail = 0;
        editDialogFormData.value.img_detail_file = [];
        editDialogFormData.value.img_detail_url = "";
    }
}
</script>

